<template>
  <div class="waveformBody">
    <div class="audio-actions">
      <div
        v-if="controls && hasPrevious"
        class="audio-action "
        @click="previous"
      >
        <fluent-icon
          icon="audio-back"
          size="16"
          view-box="0 0 16 16"
          fill-color="#734CFF"
        />
      </div>
      <div class="audio-action play-pause-action" @click="execPlayPause">
        <fluent-icon
          :icon="
            playPause === 'play' && isActiveRecord
              ? 'audio-pouse'
              : 'audio-play'
          "
          size="32"
          view-box="0 0 32 32"
          fill-color="#734CFF"
        />
      </div>
      <div v-if="controls && hasNext" class="audio-action" @click="next">
        <fluent-icon
          icon="audio-forward"
          size="16"
          view-box="0 0 16 16"
          fill-color="#734CFF"
        />
      </div>
    </div>
    <div class="audio-duration">
      <div class="color-primary">{{ currentTime }}</div>
      /{{ totalDuration }}
    </div>
    <button v-if="playbackRate" class="playback-rate" @click="toggleSpeed">
      {{ playbackRateData }}x
    </button>
    <div :id="container" class="waveform" />
    <div v-if="controls" class="audio-action" @click="close">
      <woot-button
        variant="smooth"
        size="20"
        color-scheme="secondary"
        view-box="0 0 20 20"
        class="selector-button"
        icon="audio-close"
      />
    </div>
  </div>
</template>
<script>
import WaveSurfer from 'wavesurfer.js';
import stickyBannerMixin from 'dashboard/mixins/stickyBannerMixin';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [stickyBannerMixin, alertMixin],
  props: {
    audioRecord: { type: Object, default: () => {} },
    hasNext: { type: Object, default: () => {} },
    hasPrevious: { type: Object, default: () => {} },
    autoplay: { type: Boolean, default: false },
    controls: { type: Boolean, default: false },
    playbackRate: { type: Boolean, default: false },
    muted: { type: Boolean, default: false },
  },
  data() {
    return {
      waveSurfer: null,
      currentTime: '0:00',
      totalDuration: '0:00',
      playbackRateData: 1,
      container: 'waveform',
      options: {
        container: '#waveform',
        height: 35,
        waveColor: '#F0F0F0',
        progressColor: '#734CFF',
        cursorWidth: 0,
        barWidth: 4,
        barGap: 3,
        barRadius: 3,
        width: 200,
        autoCenter: true,
        normalize: true,
        minPxPerSec: 10,
        url: '',
        backend: 'MediaElement',
      },
    };
  },
  computed: {
    isActiveRecord() {
      return this.activeRecord && this.audioRecord.id === this.activeRecord.id;
    },
  },
  watch: {
    audioRecord(newVal) {
      this.initWaveSurfer(newVal);
    },
    playPause(newVal) {
      if (newVal === 'pause') {
        this.pause();
      }
      if (newVal === 'play') {
        if (!this.isActiveRecord) {
          this.reset();
        } else {
          this.play();
          this.playbackRateData = this.rateData;
          this.speed();
        }
      }
      if (newVal === 'stop') {
        this.reset();
      }
    },
    nextPrev(newVal) {
      if (newVal === 'next') {
        if (this.isActiveRecord) {
          this.playbackRateData = this.rateData;
          this.speed();
          this.play();
        } else {
          this.reset();
        }
      }
      if (newVal === 'previous') {
        if (this.isActiveRecord) {
          this.playbackRateData = this.rateData;
          this.speed();
          this.play();
        } else {
          this.reset();
        }
      }
    },
    seekData(newVal) {
      if (this.isActiveRecord) {
        this.currentTime = this.formatTime(newVal);
        this.waveSurfer.seekTo(newVal);
      }
    },
    rateData(newVal) {
      if (this.playbackRateData !== newVal) {
        if (this.isActiveRecord) {
          this.speed(newVal);
          this.$nextTick(() => {
            this.playbackRateData = newVal;
          });
        } else {
          this.playbackRateData = 1;
          this.speed();
        }
      }
    },
    muted(newVal) {
      this.volume(newVal ? 0 : 1);
    },
  },
  mounted() {
    this.initWaveSurfer(this.audioRecord);
    if (this.isActiveRecord && this.waveSurfer) {
      this.setRateData(this.rateData);
    }
  },

  created() {
    this.container = this.generateRandomId();
    this.options.container = `#${this.container}`;
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  },
  beforeDestroy() {
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange
    );
  },

  methods: {
    handleVisibilityChange() {
      if (this.waveSurfer) {
        if (this.waveSurfer.isPlaying()) {
          this.waveSurfer.pause();
          this.waveSurfer.play();
        }
      }
    },
    async initWaveSurfer(record) {
      if (this.waveSurfer) {
        this.stop();
        this.destroy();
      }
      this.options.url = record.data_url;
      this.waveSurfer = WaveSurfer.create(this.options);
      try {
        await this.waveSurfer.load(this.options.url);
        this.waveSurfer.on('ready', this.onReady);
        this.waveSurfer.on('audioprocess', this.onAudioProcess);
        this.waveSurfer.on('seek', this.onSeek);
        this.waveSurfer.on('finish', this.onFinish);
        this.speed();
        if (this.autoplay) {
          this.play();
        }
      } catch (error) {
        this.showAlert(
          `An error occurred while trying to play the audio: ${error}`,
          'error'
        );
      }
    },
    onReady() {
      this.totalDuration = this.formatTime(this.waveSurfer.getDuration());
    },
    onAudioProcess() {
      this.currentTime = this.formatTime(this.time());
    },
    onFinish() {
      this.next();
    },
    onSeek(position) {
      const newSeekTime = this.time();
      if (!newSeekTime) return;
      if (this.isActiveRecord) {
        this.currentTime = this.formatTime(newSeekTime);
        this.$emit('seek', this.audioRecord, position);
      } else {
        this.seek(0);
      }
    },
    next() {
      this.$emit('next', this.audioRecord);
      this.$nextTick(() => {
        this.setNextPrev('null');
      });
    },
    previous() {
      this.$emit('previous', this.audioRecord);
      this.$nextTick(() => {
        this.setNextPrev('null');
      });
    },
    async play() {
      this.waveSurfer.play();
      this.$emit('play', this.audioRecord);
      this.setPlayPause('play');
    },
    pause() {
      this.waveSurfer.pause();
      this.setPlayPause('pause');
      this.$emit('pause', this.audioRecord);
    },
    stop() {
      this.waveSurfer.stop();
    },
    destroy() {
      this.waveSurfer.destroy();
    },
    speed(status = null) {
      this.waveSurfer.setPlaybackRate(status ?? this.playbackRateData);
    },
    seek(data) {
      this.waveSurfer.seekTo(data);
    },
    volume(data) {
      this.waveSurfer.setVolume(data);
    },
    mute() {
      this.waveSurfer.setVolume(0);
    },
    reset() {
      if (this.waveSurfer) {
        this.stop();
        this.seek(0);
      }
      this.playbackRateData = 1;
      this.currentTime = '0:00';
      this.speed();
    },
    close() {
      if (this.waveSurfer) {
        this.stop();
        this.destroy();
      }
      this.$emit('close');
    },
    time() {
      if (this.waveSurfer) {
        return this.waveSurfer.getCurrentTime();
      }
      return 0;
    },
    toggleSpeed() {
      if (this.isActiveRecord && this.waveSurfer) {
        if (this.playbackRateData === 1) {
          this.playbackRateData = 1.5;
        } else if (this.playbackRateData === 1.5) {
          this.playbackRateData = 2;
        } else {
          this.playbackRateData = 1;
        }
        this.$emit('rate', this.audioRecord, this.playbackRateData);
        this.speed();
      }
    },
    execPlayPause() {
      if (this.waveSurfer.isPlaying()) {
        this.pause();
      } else {
        this.play();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.waveformBody {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: white;
  padding: var(--space-smaller) 0;
  border-radius: var(--border-radius-normal);
  .waveform {
    width: 200px;
    margin: 0 var(--space-one);
    @media (max-width: 1140px) {
      display: none;
    }
  }
  .audio-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 0 var(--space-slab);
    .audio-action {
      cursor: pointer;
      display: flex;
    }
    .play-pause-action {
      margin: var(--space-zero) var(--space-smaller);
    }
  }
  .audio-duration {
    font-size: var(--font-size-mini);
    min-width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .playback-rate {
    border-radius: var(--border-radius-normal);
    font-size: var(--font-size-small);
    background-color: var(--s-100);
    cursor: pointer;
    padding: var(--space-smaller) var(--space-small);
    font-weight: var(--font-weight-medium);
    min-width: 36px;
  }
  .playback-rate-active {
    background-color: var(--v-400);
    color: var(--white);
  }
  .selector-button {
    border-radius: var(--border-radius-rounded);
    height: 3rem;
    width: 3rem;
    &:hover {
      border: 1px solid var(--color-border);
    }
  }
}
.conversation-panel > li.left .bubble .waveformBody {
  background-color: var(--s-50);
}
</style>
