<template>
  <div>
    <div class="ui-snackbar-general" :class="`type-${type}`">
      <div class="ui-snackbar">
        <fluent-icon
          icon="snackbar-clock"
          size="20"
          view-box="0 0 20 20"
          :stroke-color="icons[type]"
          stroke-width="1.25"
          fill-color="none"
          class="ui-snackbar-icon"
        />
        <div>
          <div v-if="title" class="ui-snackbar-title">{{ title }}</div>
          <div class="ui-snackbar-text">{{ message }}</div>
        </div>
      </div>
      <router-link
        v-if="action.type === 'link'"
        :to="action.to"
        class="ui-snackbar-action"
        replace
      >
        {{ action.message }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: { type: String, default: '' },
    type: { type: String, default: '' },
    title: { type: String, default: '' },
    action: {
      type: Object,
      default: () => ({
        type: '',
        message: '',
        to: '',
      }),
    },
  },
  data() {
    return {
      icons: {
        default: '#7C67E6',
        success: '#10B981',
        warning: '#EA580C',
        error: '#F43F5E',
      },
    };
  },
};
</script>
