/* global axios */
import wootConstants from 'dashboard/constants/globals.js';
import auth from './auth';
import ApiClient from './ApiClient';

class CallAPI extends ApiClient {
  SERVICES_PATH = wootConstants.SERVICES_PATH;

  requestToken() {
    const authToken = auth.getAuthToken();
    return axios.get(`/${this.SERVICES_PATH.CALL}/v1/token`, {
      headers: { authorization: authToken },
    });
  }

  rejectCall(data) {
    const authToken = auth.getAuthToken();
    return axios.post(`/${this.SERVICES_PATH.CALL}/v1/reject-call`, data, {
      headers: { authorization: authToken },
    });
  }

  getCallStatus(conversation_id) {
    const authToken = auth.getAuthToken();
    return axios.get(
      `/${this.SERVICES_PATH.CALL}/v1/call-status/${conversation_id}`,
      {
        headers: { authorization: authToken },
      }
    );
  }
}

export default new CallAPI();
