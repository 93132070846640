<template>
  <div class="settings--content">
    <div class="widget-builder-container">
      <div class="settings-container">
        <div class="settings-content">
          <form @submit.prevent="updateWidget">
            <woot-avatar-uploader
              :label="
                $t('INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.AVATAR.LABEL')
              "
              data-testid="widget-option-avatar-uploader"
              :src="avatarUrl"
              delete-avatar
              @change="handleImageUpload"
              @onAvatarDelete="handleAvatarDelete"
            />
            <woot-input
              v-model.trim="websiteName"
              :class="{ error: $v.websiteName.$error }"
              :label="
                $t(
                  'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WEBSITE_NAME.LABEL'
                )
              "
              :placeholder="
                $t(
                  'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WEBSITE_NAME.PLACE_HOLDER'
                )
              "
              :error="websiteNameValidationErrorMsg"
              @blur="$v.websiteName.$touch"
            />
            <woot-input
              v-model.trim="welcomeHeading"
              :label="
                $t(
                  'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WELCOME_HEADING.LABEL'
                )
              "
              :placeholder="
                $t(
                  'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WELCOME_HEADING.PLACE_HOLDER'
                )
              "
              data-testid="welcome-heading-widget-builder"
            />
            <woot-input
              v-model.trim="welcomeTagline"
              v-tooltip="
                $t(
                  'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TAGLINE.PLACEHOLDER'
                )
              "
              :label="
                $t(
                  'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WELCOME_TAGLINE.LABEL'
                )
              "
              :placeholder="
                $t(
                  'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TAGLINE.PLACEHOLDER'
                )
              "
              data-testid="welcome-tagline-widget-builder"
            />
            <label>
              {{
                $t('INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.REPLY_TIME.LABEL')
              }}
              <select
                v-model="replyTime"
                data-testid="reply-time-widget-builder"
              >
                <option
                  v-for="option in getReplyTimeOptions"
                  :key="option.key"
                  :value="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </label>
            <label>
              {{
                $t(
                  'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_COLOR_LABEL'
                )
              }}
              <woot-color-picker v-model="color" />
            </label>
            <input-radio-group
              name="widget-bubble-position"
              :label="
                $t(
                  'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_BUBBLE_POSITION_LABEL'
                )
              "
              :items="
                widgetBubblePositions.map(item => ({
                  ...item,
                  checked: item.id === widgetSettings.position,
                }))
              "
              :action="handleWidgetBubblePositionChange"
            />
            <input-radio-group
              name="widget-bubble-type"
              :label="
                $t(
                  'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_BUBBLE_TYPE_LABEL'
                )
              "
              :items="
                widgetBubbleTypes.map(item => ({
                  ...item,
                  checked: item.id === widgetSettings.type,
                }))
              "
              :action="handleWidgetBubbleTypeChange"
            />
            <woot-input
              v-if="widgetSettings.type === 'expanded_bubble'"
              v-model.trim="widgetSettings.launcherTitle"
              data-testid="expanded-bubble-title-input"
              :label="
                $t(
                  'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_BUBBLE_LAUNCHER_TITLE.LABEL'
                )
              "
              :placeholder="
                $t(
                  'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_BUBBLE_LAUNCHER_TITLE.PLACE_HOLDER'
                )
              "
            />
            <input-radio-group
              name="allow-widget-maximize"
              :label="
                $t(
                  'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.ALLOW_WIDGET_MAXIMIZE'
                )
              "
              :items="
                widgetMaximizeOptions.map(item => ({
                  ...item,
                  checked: item.id === widgetSettings.allowWidgetMaximize,
                }))
              "
              :action="handleWidgetMaximizeChange"
            />
            <div class="special-settings">
              <input
                id="hide_message_bubble"
                type="checkbox"
                value="hide_message_bubble"
                :checked="widgetSettings.hideMessageBubble"
                @input="handleHideMessageBubbleChange"
              />
              <div>
                <label for="hide_message_bubble">
                  {{
                    $t(
                      'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.HIDE_MESSAGE_BUBBLE'
                    )
                  }}
                </label>

                <span>
                  {{
                    $t(
                      'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.HIDE_MESSAGE_BUBBLE_DESCRIPTION'
                    )
                  }}
                </span>
              </div>
            </div>
            <label for="use_browser_language">
              <input
                id="use_browser_language"
                type="checkbox"
                value="use_browser_language"
                :checked="widgetSettings.useBrowserLanguage"
                @input="handleUseBrowserLanguageChange"
              />
              {{
                $t(
                  'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.USE_BROWSER_LANGUAGE'
                )
              }}
            </label>

            <label>
              {{
                $t('INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.DEFAULT_LANGUAGE')
              }}
              <select
                v-model="widgetSettings.locale"
                :disabled="widgetSettings.useBrowserLanguage"
              >
                <option
                  v-for="option in getDefaultLanguageOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
              <span class="helper-text">
                {{
                  $t(
                    'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.DEFAULT_LANGUAGE_DESCRIPTION'
                  )
                }}
              </span>
            </label>

            <woot-submit-button
              class="submit-button"
              :button-text="
                $t(
                  'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.UPDATE.BUTTON_TEXT'
                )
              "
              :loading="uiFlags.isUpdating"
              :disabled="$v.$invalid || uiFlags.isUpdating"
            />
          </form>
        </div>
      </div>
      <div class="widget-container">
        <div v-if="isWidgetPreview" class="widget-preview">
          <Widget
            :welcome-heading="welcomeHeading"
            :welcome-tagline="welcomeTagline"
            :website-name="websiteName"
            :logo="avatarUrl"
            is-online
            :reply-time="replyTime"
            :color="color"
            :widget-bubble-position="widgetSettings.position"
            :widget-bubble-launcher-title="widgetSettings.launcherTitle"
            :widget-bubble-type="widgetSettings.type"
          />
        </div>
        <div v-else class="widget-script">
          <woot-code :script="widgetScript" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Widget from 'dashboard/modules/widget-preview/components/Widget';
import InputRadioGroup from './components/InputRadioGroup';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import { LOCAL_STORAGE_KEYS } from 'dashboard/constants/localStorage';
import { LocalStorage } from 'shared/helpers/localStorage';

export default {
  components: {
    Widget,
    InputRadioGroup,
  },
  mixins: [alertMixin],
  props: {
    inbox: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isWidgetPreview: true,
      color: '#623bea',
      websiteName: '',
      welcomeHeading: '',
      welcomeTagline: '',
      replyTime: 'in_a_few_minutes',
      avatarFile: null,
      avatarUrl: '',
      widgetSettings: {
        position: 'right',
        type: 'standard',
        allowWidgetMaximize: 'enabled',
        launcherTitle: this.$t(
          'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_BUBBLE_LAUNCHER_TITLE.DEFAULT'
        ),
        locale: 'en',
        useBrowserLanguage: false,
      },
      widgetBubblePositions: [
        {
          id: 'left',
          title: this.$t(
            'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_BUBBLE_POSITION.LEFT'
          ),
        },
        {
          id: 'right',
          title: this.$t(
            'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_BUBBLE_POSITION.RIGHT'
          ),
        },
      ],
      widgetBubbleTypes: [
        {
          id: 'standard',
          title: this.$t(
            'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_BUBBLE_TYPE.STANDARD'
          ),
        },
        {
          id: 'expanded_bubble',
          title: this.$t(
            'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_BUBBLE_TYPE.EXPANDED_BUBBLE'
          ),
        },
      ],
      widgetMaximizeOptions: [
        {
          id: 'enabled',
          title: this.$t(
            'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.ALLOW_WIDGET_MAXIMIZE_OPTIONS.ENABLED'
          ),
        },
        {
          id: 'disabled',
          title: this.$t(
            'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.ALLOW_WIDGET_MAXIMIZE_OPTIONS.DISABLED'
          ),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
    storageKey() {
      return `${LOCAL_STORAGE_KEYS.WIDGET_BUILDER}${this.inbox.id}`;
    },
    widgetScript() {
      // let options = {
      //   position: this.widgetBubblePosition,
      //   type: this.widgetBubbleType,
      //   launcherTitle: this.widgetBubbleLauncherTitle,
      // };
      // let script = this.inbox.web_widget_script;
      // return (
      //   script.substring(0, 13) +
      //   this.$t('INBOX_MGMT.WIDGET_BUILDER.SCRIPT_SETTINGS', {
      //     options: JSON.stringify(options),
      //   }) +
      //   script.substring(13)
      // );
      return this.inbox.web_widget_script;
    },
    getWidgetViewOptions() {
      return [
        {
          id: 'preview',
          title: this.$t(
            'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_VIEW_OPTION.PREVIEW'
          ),
          checked: true,
        },
        {
          id: 'script',
          title: this.$t(
            'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_VIEW_OPTION.SCRIPT'
          ),
          checked: false,
        },
      ];
    },
    getReplyTimeOptions() {
      return [
        {
          key: 'in_a_few_minutes',
          value: 'in_a_few_minutes',
          text: this.$t(
            'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.REPLY_TIME.IN_A_FEW_MINUTES'
          ),
        },
        {
          key: 'in_a_few_hours',
          value: 'in_a_few_hours',
          text: this.$t(
            'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.REPLY_TIME.IN_A_FEW_HOURS'
          ),
        },
        {
          key: 'in_a_day',
          value: 'in_a_day',
          text: this.$t(
            'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.REPLY_TIME.IN_A_DAY'
          ),
        },
      ];
    },
    getDefaultLanguageOptions() {
      return [
        {
          value: 'ar',
          text: 'Arabic',
        },
        {
          value: 'hy',
          text: 'Armenian',
        },
        {
          value: 'pt_BR',
          text: 'Brazilian Portuguese',
        },
        {
          value: 'bg',
          text: 'Bulgarian',
        },
        {
          value: 'ca',
          text: 'Catalan',
        },
        {
          value: 'zh',
          text: 'Chinese',
        },
        {
          value: 'zh_CN',
          text: 'Chinese (Simplified)',
        },
        {
          value: 'zh_TW',
          text: 'Chinese (Traditional)',
        },
        {
          value: 'hr',
          text: 'Croatian',
        },
        {
          value: 'cs',
          text: 'Czech',
        },
        {
          value: 'da',
          text: 'Danish',
        },
        {
          value: 'nl',
          text: 'Dutch',
        },
        {
          value: 'en',
          text: 'English',
        },
        {
          value: 'fi',
          text: 'Finnish',
        },
        {
          value: 'fr',
          text: 'French',
        },
        {
          value: 'ka',
          text: 'Georgian',
        },
        {
          value: 'de',
          text: 'German',
        },
        {
          value: 'el',
          text: 'Greek',
        },
        {
          value: 'he',
          text: 'Hebrew',
        },
        {
          value: 'hi',
          text: 'Hindi',
        },
        {
          value: 'hu',
          text: 'Hungarian',
        },
        {
          value: 'is',
          text: 'Icelandic',
        },
        {
          value: 'id',
          text: 'Indonesian',
        },
        {
          value: 'it',
          text: 'Italian',
        },
        {
          value: 'ja',
          text: 'Japanese',
        },
        {
          value: 'ko',
          text: 'Korean',
        },
        {
          value: 'lv',
          text: 'Latvian',
        },
        {
          value: 'lt',
          text: 'Lithuanian',
        },
        {
          value: 'ms',
          text: 'Malay',
        },
        {
          value: 'ml',
          text: 'Malayalam',
        },
        {
          value: 'ne',
          text: 'Nepali',
        },
        {
          value: 'no',
          text: 'Norwegian',
        },
        {
          value: 'fa',
          text: 'Persian',
        },
        {
          value: 'pl',
          text: 'Polish',
        },
        {
          value: 'pt',
          text: 'Portuguese',
        },
        {
          value: 'ro',
          text: 'Romanian',
        },
        {
          value: 'ru',
          text: 'Russian',
        },
        {
          value: 'sr',
          text: 'Serbian',
        },
        {
          value: 'sh',
          text: 'Serbo-Croatian',
        },
        {
          value: 'sk',
          text: 'Slovak',
        },
        {
          value: 'es',
          text: 'Spanish',
        },
        {
          value: 'sv',
          text: 'Swedish',
        },
        {
          value: 'ta',
          text: 'Tamil',
        },
        {
          value: 'th',
          text: 'Thai',
        },
        {
          value: 'tr',
          text: 'Turkish',
        },
        {
          value: 'uk',
          text: 'Ukrainian',
        },
        {
          value: 'ur',
          text: 'Urdu',
        },
        {
          value: 'ur_IN',
          text: 'Urdu (India)',
        },
        {
          value: 'vi',
          text: 'Vietnamese',
        },
      ];
    },
    websiteNameValidationErrorMsg() {
      return this.$v.websiteName.$error
        ? this.$t('INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WEBSITE_NAME.ERROR')
        : '';
    },
  },
  // watch: {
  //   widgetBubbleLauncherTitle() {
  //     this.updateStorage();
  //   },
  // },
  created() {
    this.setDefaults();
  },
  validations: {
    websiteName: { required },
  },
  methods: {
    setDefaults() {
      // Widget Settings
      const {
        name,
        welcome_title,
        welcome_tagline,
        widget_color,
        reply_time,
        avatar_url,
        widget_settings,
      } = this.inbox;
      this.websiteName = name;
      this.welcomeHeading = welcome_title;
      this.welcomeTagline = welcome_tagline;
      this.color = widget_color;
      this.replyTime = reply_time;
      this.avatarUrl = avatar_url;
      const dbWidgetSettings = JSON.parse(widget_settings);
      if (Object.keys(dbWidgetSettings).length > 0) {
        this.widgetSettings = {
          ...this.widgetSettings,
          ...dbWidgetSettings,
        };
      }

      const savedInformation = this.getSavedInboxInformation();
      if (Object.keys(savedInformation).length > 0) {
        this.widgetSettings = {
          position: savedInformation.position,
          type: savedInformation.type,
          allowWidgetMaximize: 'enabled',
          launcherTitle: savedInformation.launcherTitle || 'Chat with us',
          ...this.widgetSettings,
        };
      }
    },
    handleWidgetBubblePositionChange(item) {
      this.widgetSettings.position = item.id;
      // this.updateStorage();
    },
    handleWidgetBubbleTypeChange(item) {
      this.widgetSettings.type = item.id;
      // this.updateStorage();
    },
    handleWidgetMaximizeChange(item) {
      this.widgetSettings.allowWidgetMaximize = item.id;
    },
    handleHideMessageBubbleChange(e) {
      this.widgetSettings.hideMessageBubble = e.target.checked;
    },
    handleUseBrowserLanguageChange(e) {
      this.widgetSettings = {
        ...this.widgetSettings,
        useBrowserLanguage: e.target.checked,
      };
    },
    handleWidgetViewChange(item) {
      this.isWidgetPreview = item.id === 'preview';
    },
    handleImageUpload({ file, url }) {
      this.avatarFile = file;
      this.avatarUrl = url;
    },
    async handleAvatarDelete() {
      try {
        await this.$store.dispatch('inboxes/deleteInboxAvatar', this.inbox.id);
        this.avatarFile = null;
        this.avatarUrl = '';
        this.showAlert(
          this.$t(
            'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.AVATAR.DELETE.API.SUCCESS_MESSAGE'
          ),
          'success'
        );
      } catch (error) {
        this.showAlert(
          error.message
            ? error.message
            : this.$t(
                'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.AVATAR.DELETE.API.ERROR_MESSAGE'
              ),
          'error'
        );
      }
    },
    // updateStorage() {
    //   const bubbleSettings = {
    //     position: this.widgetBubblePosition,
    //     launcherTitle: this.widgetBubbleLauncherTitle,
    //     type: this.widgetBubbleType,
    //     allowWidgetMaximize: this.allowWidgetMaximize,
    //   };
    //
    //   LocalStorage.set(this.storageKey, bubbleSettings);
    // },
    async updateWidget() {
      // this.updateStorage();

      try {
        const payload = {
          id: this.inbox.id,
          name: this.websiteName,
          channel: {
            widget_color: this.color,
            widget_settings: JSON.stringify(this.widgetSettings),
            welcome_title: this.welcomeHeading,
            welcome_tagline: this.welcomeTagline,
            reply_time: this.replyTime,
          },
        };
        if (this.avatarFile) {
          payload.avatar = this.avatarFile;
        }
        await this.$store.dispatch('inboxes/updateInbox', payload);
        this.showAlert(
          this.$t(
            'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.UPDATE.API.SUCCESS_MESSAGE'
          ),
          'success'
        );
      } catch (error) {
        this.showAlert(
          error.message ||
            this.$t(
              'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.UPDATE.API.ERROR_MESSAGE'
            ),
          'error'
        );
      }
    },
    getSavedInboxInformation() {
      return LocalStorage.get(this.storageKey);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/woot';

.widget-builder-container {
  display: flex;
  flex-direction: row;
  padding: var(--space-one) 0;
  @include breakpoint(900px down) {
    flex-direction: column;
  }
}

.settings-container {
  width: 40%;
  @include breakpoint(900px down) {
    width: 100%;
  }

  .settings-content {
    overflow-y: scroll;
    min-height: 100%;

    .submit-button {
      margin-top: var(--space-normal);
    }
  }
  .special-settings {
    display: flex;
    flex-direction: row;
    // padding: var(--space-normal);
    // background: var(--s-25);
    border-radius: var(--border-radius-medium);
    position: relative;
    gap: 12px;
    align-items: center;
    margin: 2px 0 20px 0;
    input {
      margin: 0 !important;
    }
    svg {
      position: absolute;
      top: 2rem;
      right: 2rem;
      width: 1.8rem;
      height: 1.8rem;
    }
    div {
      display: flex;
      flex-direction: column;
    }
    span {
      font-size: 12px;
    }
  }
}

.widget-container {
  width: 60%;

  @include breakpoint(900px down) {
    width: 100%;
  }

  .widget-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    min-height: 65rem;
    margin: var(--space-zero) var(--space-two) var(--space-two) var(--space-two);
    padding: var(--space-one) var(--space-one) var(--space-one) var(--space-one);
    background: var(--s-50);
    border-radius: 1.6rem;
    height: 100%;
    max-height: calc(95vh - var(--space-giga));
    @include breakpoint(500px down) {
      background: none;
    }
  }
  .widget-script {
    margin: 0 var(--space-two);
    padding: var(--space-one);
    background: var(--s-50);
  }
}

.helper-text {
  font-size: 12px;
  margin-top: -10px;
  display: block;
  font-weight: 400;
}
</style>

/* */
