const getLastNonActivityMessage = (messageInStore, messageFromAPI) => {
  // If both API value and store value for last non activity message
  // are available, then return the latest one.
  if (messageInStore && messageFromAPI) {
    if (messageInStore.created_at >= messageFromAPI.created_at) {
      return messageInStore;
    }
    return messageFromAPI;
  }

  // Otherwise, return whichever is available
  return messageInStore || messageFromAPI;
};

export const filterDuplicateSourceMessages = (messages = []) => {
  const messagesWithoutDuplicates = [];
  // We cannot use Map or any short hand method as it returns the last message with the duplicate ID
  // We should return the message with smaller id when there is a duplicate
  messages.forEach(m1 => {
    if (m1.source_id) {
      if (
        messagesWithoutDuplicates.findIndex(
          m2 => m1.source_id === m2.source_id
        ) < 0
      ) {
        messagesWithoutDuplicates.push(m1);
      }
    } else {
      messagesWithoutDuplicates.push(m1);
    }
  });
  return messagesWithoutDuplicates;
};

import callApi from 'dashboard/api/call';

export default {
  methods: {
    lastMessage(m) {
      let lastMessageIncludingActivity = m.messages.last();

      const nonActivityMessages = m.messages.filter(
        message => message.message_type !== 2
      );
      let lastNonActivityMessageInStore = nonActivityMessages.last();
      let lastNonActivityMessageFromAPI = m.last_non_activity_message;

      // If API value and store value for last non activity message
      // is empty, then return the last activity message
      if (!lastNonActivityMessageInStore && !lastNonActivityMessageFromAPI) {
        return lastMessageIncludingActivity;
      }

      return getLastNonActivityMessage(
        lastNonActivityMessageInStore,
        lastNonActivityMessageFromAPI
      );
    },
    readMessages(messages, agentLastSeenAt) {
      return messages.filter(
        message => message.created_at * 1000 <= agentLastSeenAt * 1000
      );
    },
    unReadMessages(messages, agentLastSeenAt) {
      return messages.filter(
        message => message.created_at * 1000 > agentLastSeenAt * 1000
      );
    },
    async checkToggleStatus(status, snoozedUntil, chat) {
      if (!this.isOpenCallChannel(chat)) {
        this.execToggleStatus(status, snoozedUntil, chat.id);
      } else {
        try {
          const req = await callApi.getCallStatus(chat.id);
          if (
            req.data.data.foundInStorage ||
            req.data.data.foundInTwilio ||
            req.data.data.call
          ) {
            this.showAlert(
              this.$t('CONVERSATION.CHANGE_STATUS_ONGOING'),
              'success'
            );
          } else {
            this.execToggleStatus(status, snoozedUntil, chat.id);
          }
        } catch (error) {
          this.showAlert(error.message, 'error');
        }
      }
    },
    execToggleStatus(status, snoozedUntil, conversationId) {
      this.isLoading = true;
      this.$store
        .dispatch('toggleStatus', {
          conversationId,
          status,
          snoozedUntil,
        })
        .then(() => {
          this.showAlert(this.$t('CONVERSATION.CHANGE_STATUS'), 'success');
          this.isLoading = false;
        });
    },
    isCallChannel(chat) {
      return [chat.meta.channel, chat.channel].includes('Channel::Call');
    },
    isOpenCallChannel(chat) {
      return this.isCallChannel(chat) && chat.status === 'open';
    },
    isPendingCallChannel(chat) {
      return this.isCallChannel(chat) && chat.status === 'pending';
    },
  },
};
