/* global axios */
import ApiClient from './ApiClient';

class Agents extends ApiClient {
  constructor() {
    super('agents', { accountScoped: true });
  }

  get(page) {
    return axios.get(this.url, {
      params: {
        page,
      },
    });
  }
}

export default new Agents();
