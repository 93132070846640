<template>
  <div class="column content-box">
    <div class="row table-view">
      <div class="small-8 columns with-right-space">
        <div
          v-if="!inboxesList.length && !isLoading"
          class="no-items-error-message"
        >
          <img :src="noChannelSvg" class="no-channel-image" />
          <h6 class="fw-bold fs-medium">
            {{ $t('SETTINGS.INBOXES.NEW_INBOX_TITLE') }}
          </h6>
          <div class="fw-normal fs-default margin-bottom-2">
            {{ $t('SETTINGS.INBOXES.NEW_INBOX_DESC') }}
          </div>
          <router-link
            v-if="isAdmin"
            :to="addAccountScoping('settings/inboxes/new')"
          >
            {{ $t('SETTINGS.INBOXES.NEW_INBOX') }}
          </router-link>
        </div>
        <spinner-hoory v-if="isLoading" />
        <ChannelTable
          v-else
          :items="inboxesList"
          @deleteChannel="openDelete"
          @redirectToSettings="redirectToChannelSettings"
        />
        <table-footer
          :current-page="Number(meta.currentPage)"
          :total-count="meta.count"
          :page-size="20"
          @page-change="onPageChange"
        />
      </div>

      <div class="small-4 columns">
        <span
          v-dompurify-html="
            useInstallationName(
              $t('INBOX_MGMT.SIDEBAR_TXT'),
              globalConfig.installationName
            )
          "
        />
      </div>
    </div>
    <settings
      v-if="showSettings"
      :show.sync="showSettings"
      :on-close="closeSettings"
      :inbox="selectedInbox"
    />

    <woot-confirm-delete-modal
      v-if="showDeletePopup"
      :show.sync="showDeletePopup"
      :title="$t('INBOX_MGMT.DELETE.CONFIRM.TITLE')"
      :description="confirmDescription"
      :message="confirmDeleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
      :confirm-value="selectedInbox.name"
      :confirm-place-holder-text="confirmPlaceHolderText"
      @on-confirm="confirmDeletion"
      @on-close="closeDelete"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Settings from './Settings';
import adminMixin from '../../../../mixins/isAdmin';
import billingMixin from 'dashboard/mixins/billing';
import accountMixin from 'dashboard/mixins/account';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import noChannelSvg from 'dashboard/assets/icons/call/noChannel.svg';
import ChannelTable from './ChannelTable.vue';
import TableFooter from '../../../../components/widgets/TableFooter.vue';
import SpinnerHoory from '../../../../../shared/components/SpinnerHoory.vue';

export default {
  components: {
    SpinnerHoory,
    ChannelTable,
    Settings,
    TableFooter,
  },
  mixins: [adminMixin, accountMixin, globalConfigMixin, billingMixin],
  data() {
    return {
      isLoading: false,
      showSettings: false,
      showDeletePopup: false,
      selectedInbox: {},
      confirmDescription: '',
      noChannelSvg,
    };
  },
  computed: {
    ...mapGetters({
      inboxesList: 'inboxes/getInboxes',
      meta: 'inboxes/getMeta',
      globalConfig: 'globalConfig/get',
    }),
    // Delete Modal
    deleteConfirmText() {
      return `${this.$t('INBOX_MGMT.DELETE.CONFIRM.YES')} ${
        this.selectedInbox.name
      }`;
    },
    deleteRejectText() {
      return `${this.$t('INBOX_MGMT.DELETE.CONFIRM.NO')} ${
        this.selectedInbox.name
      }`;
    },
    confirmDeleteMessage() {
      return `${this.$t('INBOX_MGMT.DELETE.CONFIRM.MESSAGE')} ${
        this.selectedInbox.name
      } ?`;
    },
    confirmPlaceHolderText() {
      return `${this.$t('INBOX_MGMT.DELETE.CONFIRM.PLACE_HOLDER', {
        inboxName: this.selectedInbox.name,
      })}`;
    },
  },
  mounted() {
    this.fetchChannels(this.meta.currentPage);
  },
  methods: {
    onPageChange(page) {
      this.fetchChannels(page);
    },
    openSettings(inbox) {
      this.showSettings = true;
      this.selectedInbox = inbox;
    },
    closeSettings() {
      this.showSettings = false;
      this.selectedInbox = {};
    },
    redirectToChannelSettings(row) {
      this.$router.push(
        `${this.addAccountScoping(`settings/inboxes/${row.id}`)}`
      );
    },
    async fetchChannels(page) {
      this.isLoading = true;
      await this.$store.dispatch('inboxes/get', { page });
      this.isLoading = false;
    },
    async deleteInbox(inbox) {
      let responseMessage = '';
      try {
        if (inbox.channel_type === 'Channel::Call') {
          await this.deleteCallBaseChannel({
            inboxId: inbox.id,
            accountId: this.accountId,
          });
          await this.$store.dispatch('inboxes/updateMeta', {
            count: this.meta.count - 1,
            current_page: this.meta.currentPage,
          });
        } else {
          const response = await this.$store.dispatch(
            'inboxes/delete',
            inbox.id
          );
          if (response?.message) {
            responseMessage += response.message;
          }
          await this.$store.dispatch('inboxes/updateMeta', {
            count: this.meta.count - 1,
            current_page: this.meta.currentPage,
          });
        }
        bus.$emit(
          'newToastMessage',
          responseMessage || this.$t('INBOX_MGMT.DELETE.API.SUCCESS_MESSAGE')
        );
      } catch (error) {
        bus.$emit(
          'newToastMessage',
          this.$t('INBOX_MGMT.DELETE.API.ERROR_MESSAGE')
        );
      }
    },

    confirmDeletion() {
      this.deleteInbox(this.selectedInbox);
      this.closeDelete();
    },
    openDelete(inbox) {
      this.confirmDescription =
        inbox.channel_type === 'Channel::Call'
          ? this.$t('INBOX_MGMT.DELETE.CONFIRM.CALL_DESCRIPTION', {
              inboxName: inbox.name,
            })
          : '';
      this.showDeletePopup = true;
      this.selectedInbox = inbox;
    },
    closeDelete() {
      this.showDeletePopup = false;
      this.selectedInbox = {};
    },
  },
};
</script>
