<template>
  <div class="columns small-12">
    <div class="medium-12 columns">
      <page-header
        :header-content="$t('INBOX_MGMT.ADD.EMAIL_DNS.DESC')"
        :header-title="$t('INBOX_MGMT.ADD.EMAIL_DNS.TITLE')"
      />
    </div>
    <div v-if="gettingDnsRecords">
      <p class="center bold">
        {{ $t('INBOX_MGMT.ADD.EMAIL_DNS.GETTING_DNS_RECORDS') }}
      </p>
    </div>
    <div v-if="isVerifying">
      <p class="center bold">
        {{ $t('INBOX_MGMT.ADD.EMAIL_DNS.VERIFYING_DNS_RECORDS') }}
      </p>
    </div>
    <form v-if="!gettingDnsRecords" class="row" @submit.prevent>
      <div class="medium-12 columns">
        <div class="medium-12 columns">
          <div
            v-for="(item, index) in getDnsRecords()"
            :key="item.host"
            class="medium-12 records-container"
          >
            <div class="row">
              <div class="medium-1 header">
                Type:
              </div>
              <div class="medium-11 record">
                CNAME ( {{ index }} )
                <span
                  v-if="
                    verification_status[index] &&
                      verification_status[index]['valid']
                  "
                  class="success-message"
                >
                  {{ $t('INBOX_MGMT.ADD.EMAIL_DNS.VERIFIED') }}
                </span>
                <span
                  v-if="
                    verification_status[index] &&
                      !verification_status[index]['valid']
                  "
                  class="error-message"
                >
                  {{ $t('INBOX_MGMT.ADD.EMAIL_DNS.FAILED') }}
                </span>
              </div>
              <div
                v-if="
                  verification_status[index] !== undefined &&
                    !verification_status[index]['valid']
                "
                class="medium-12 error-message"
              >
                {{ verification_status[index]['reason'] }}
              </div>
            </div>
            <div class="row">
              <div class="medium-1 header">
                Host:
              </div>
              <div class="medium-11 record">
                <woot-code :script="item['host']" />
              </div>
            </div>
            <div class="row">
              <div class="medium-1 header">
                Value:
              </div>
              <div class="medium-11 record">
                <woot-code :script="item['data']" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="medium-12 columns submit-btn-holder">
        <woot-button :is-loading="isVerifying" @click="verifyDnsRecords()">
          {{ $t('INBOX_MGMT.ADD.EMAIL_DNS.VERIFY_BUTTON') }}
        </woot-button>
        <woot-button
          :is-loading="isSaving"
          @click="saveSenderMethod(true, isVerified)"
        >
          {{ $t('INBOX_MGMT.ADD.EMAIL_DNS.SAVE_BUTTON') }}
        </woot-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PageHeader from '../SettingsSubPageHeader';
import axios from 'axios';
import wootConstants from '../../../../constants/globals';
import utils from './utils';
import WootButton from '../../../../components/ui/WootButton.vue';
import alertMixin from '../../../../../shared/mixins/alertMixin';
const { SERVICES_PATH } = wootConstants;

export default {
  components: {
    WootButton,
    PageHeader,
  },
  mixins: [alertMixin],
  data() {
    return {
      isVerifying: false,
      isSaving: false,
      gettingDnsRecords: true,
      dns_records: {},
      verification_status: {},
      isVerified: true,
    };
  },
  validations: {},
  computed: {
    ...mapGetters({
      inboxes: 'inboxes/getInboxes',
    }),
  },

  async mounted() {
    const inbox_id = this.$route.params.inboxId;
    let current_inbox = this.inboxes.filter(inbox => {
      return inbox.id === parseInt(inbox_id, 10);
    });
    let url = `/${SERVICES_PATH.WEB_SIDECAR}/v1/inbox/mail/sender/auth`;
    let data = { domain: utils.getDomainFromEmail(current_inbox[0].email) };
    await axios
      .post(url, data)
      .then(response => {
        if (response.status !== 200) {
          bus.$emit('newToastMessage', 'error getting dns records!');
        } else {
          this.verifyDnsRecords();
          this.dns_records = response.data;
          this.gettingDnsRecords = false;
        }
      })
      .catch(() => {
        this.showAlert('error getting dns records!', 'error');
      });
  },

  methods: {
    getDnsRecords() {
      return this.dns_records.records;
    },
    async verifyDnsRecords() {
      this.isVerifying = true;
      const inbox_id = this.$route.params.inboxId;
      let current_inbox = this.inboxes.filter(inbox => {
        return inbox.id === parseInt(inbox_id, 10);
      });
      let data = { domain: utils.getDomainFromEmail(current_inbox[0].email) };
      let url = `/${SERVICES_PATH.WEB_SIDECAR}/v1/inbox/mail/sender/validate`;
      await axios
        .post(url, data)
        .then(response => {
          if (response.status !== 200) {
            this.showAlert('error verifying dns records!', 'success');
          } else {
            this.isVerified = response.data.status.valid;
            // this.saveSenderMethod(undefined, this.isVerified);
            this.verification_status = response.data.status.validation_results;
          }
        })
        .catch(() => {
          this.showAlert('error verifying dns records!', 'error');
        });
      this.isVerifying = false;
    },
    async saveSenderMethod(dns_enabled, dns_verified) {
      const inbox_id = this.$route.params.inboxId;
      try {
        this.isSaving = true;
        let payload = {
          id: inbox_id,
          formData: false,
          channel: {
            dns_enabled: dns_enabled ?? undefined,
            dns_verified: dns_verified ?? undefined,
            imap_enabled: false,
          },
        };
        await this.$store.dispatch('inboxes/updateInboxDns', payload);
        this.isSaving = false;
        this.showAlert(
          this.$t('INBOX_MGMT.ADD.EMAIL_DNS.SUCCESS_MESSAGE'),
          'success'
        );
      } catch (error) {
        this.showAlert(error.message, 'error');
      }
    },
  },
};
</script>

<style>
.bold {
  font-weight: bold;
}

.center {
  text-align: center;
}

.error-message {
  color: red;
  font-size: var(--font-size-micro);
}

.success-message {
  color: var(--g-500);
  font-size: var(--font-size-micro);
}

.records-container {
  margin-top: var(--space-one);
  border: 0.1rem solid var(--color-border-light);
  border-radius: 0.5rem;
  padding: 0.5rem;
  font-size: 1.3rem;

  .record {
    padding: 0.5rem;
  }

  .header {
    display: flex;
    align-items: center;
    padding: 0.5rem;
  }
}
.submit-btn-holder {
  margin-top: var(--space-small);
}
</style>
