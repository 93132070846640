<template>
  <div class="wizard-body columns small-9">
    <form class="row" @submit.prevent="addAgents()">
      <div class="medium-12 columns">
        <page-header
          :header-title="$t('INBOX_MGMT.ADD.AGENTS.TITLE')"
          :header-content="$t('INBOX_MGMT.ADD.AGENTS.DESC')"
        />
      </div>
      <div class="medium-7 columns">
        <div class="medium-12 columns">
          <label :class="{ error: $v.selectedAgents.$error }">
            {{ $t('INBOX_MGMT.ADD.AGENTS.TITLE') }}
            <multiselect
              v-model="selectedAgents"
              :options="agentList"
              track-by="id"
              label="name"
              :limit="2"
              :limit-text="count => '+' + count"
              class="no-margin"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :hide-selected="true"
              selected-label
              :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
              :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
              :placeholder="$t('INBOX_MGMT.ADD.AGENTS.PICK_AGENTS')"
              @select="$v.selectedAgents.$touch"
            />
            <span v-if="$v.selectedAgents.$error" class="message">
              {{ $t('INBOX_MGMT.ADD.AGENTS.VALIDATION_ERROR') }}
            </span>
          </label>
        </div>
        <div class="medium-12 columns">
          <woot-submit-button
            :button-text="$t('INBOX_MGMT.AGENTS.BUTTON_TEXT')"
            :loading="isCreating"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
/* eslint no-console: 0 */
import { mapGetters } from 'vuex';

import InboxMembersAPI from '../../../../api/inboxMembers';
import router from '../../../index';
import PageHeader from '../SettingsSubPageHeader';
import inboxMixin from 'shared/mixins/inboxMixin';
import onboardingMixin, { ACTIONS } from '../../../../mixins/onboardingMixin';

export default {
  components: {
    PageHeader,
  },
  mixins: [inboxMixin, onboardingMixin],

  validations: {
    selectedAgents: {
      isEmpty() {
        return !!this.selectedAgents.length;
      },
    },
  },

  data() {
    return {
      selectedAgents: [],
      isCreating: false,
    };
  },

  computed: {
    ...mapGetters({
      agentList: 'agents/getAgents',
      newInboxType: 'inboxes/getNewInboxType',
    }),
  },

  mounted() {
    this.$store.dispatch('agents/get');
    this.fetchAttachedAgents();
  },
  methods: {
    async addAgents() {
      this.isCreating = true;
      const isNewInboxCall = this.newInboxType === 'call';
      const inboxId = this.$route.params.inbox_id;
      const selectedAgents = this.selectedAgents.map(x => x.id);

      try {
        // Make sure that call channel has at least one assignee
        if (!isNewInboxCall || selectedAgents.length) {
          await InboxMembersAPI.update({ inboxId, agentList: selectedAgents });
          await this.$store.dispatch('inboxes/get', { page: 1 });
        }

        this.sendProductFruitsEvent(ACTIONS.INBOX_AGENTS_ADDED);
        router.replace({
          name: isNewInboxCall
            ? 'settings_inboxes_attributes'
            : 'settings_inbox_finish',
          params: {
            page: 'new',
            inbox_id: this.$route.params.inbox_id,
          },
        });
      } catch (error) {
        bus.$emit('newToastMessage', error.message);
      }
      this.isCreating = false;
    },
    async fetchAttachedAgents() {
      const response = await this.$store.dispatch('inboxMembers/get', {
        inboxId: this.$route.params.inbox_id,
      });
      const {
        data: { payload: inboxMembers },
      } = response;
      this.selectedAgents = inboxMembers;
    },
  },
};
</script>
<style lang="scss" scoped>
.no-margin {
  &::v-deep .multiselect__strong {
    margin-right: var(--space-smaller) !important;
    margin-bottom: 0 !important;
    line-height: 0 !important;
    font-weight: 400 !important;
    font-size: var(--font-size-small) !important;
  }
  &::v-deep .multiselect__tag {
    max-width: 150px !important;
    @media screen and (min-width: 1250px) {
      max-width: 80px !important;
    }
  }
}
</style>
