<template>
  <div class="wizard-body call-based-body columns small-9">
    <div>
      <page-header
        class="channel-type-header"
        :header-title="$t('INBOX_MGMT.ADD.CALL_CHANNEL.GREETING.TITLE')"
        :header-content="$t('INBOX_MGMT.ADD.CALL_CHANNEL.GREETING.DESCRIPTION')"
      />
      <div class="attributes-content">
        <section class="margin-top-3">
          <label>
            {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.GREETING.LABEL') }}
            <textarea
              v-model.trim="greetingMessage"
              class="margin-bottom-zero"
              rows="5"
              type="text"
              :placeholder="getDefaultGreeting"
              @input="$v.greetingMessage.$touch"
            />
            <span v-if="!$v.greetingMessage.minLength" class="validate-error">{{
              $t('INBOX_MGMT.ADD.CALL_CHANNEL.GREETING.ERROR_MIN', {
                count: 50,
              })
            }}</span>
            <span v-if="!$v.greetingMessage.maxLength" class="validate-error">{{
              $t('INBOX_MGMT.ADD.CALL_CHANNEL.GREETING.ERROR_MAX', {
                count: 250,
              })
            }}</span>
          </label>
        </section>
      </div>
    </div>
    <div>
      <woot-submit-button
        :button-text="$t('INBOX_MGMT.ADD.CALL_CHANNEL.GREETING.BUTTON')"
        type="button"
        class="next-btn"
        @click="updateInbox()"
      />
    </div>
  </div>
</template>

<script>
import inboxMixin from 'shared/mixins/inboxMixin';
import PageHeader from '../../../SettingsSubPageHeader';
import alertMixin from 'shared/mixins/alertMixin';
import { maxLength, minLength } from 'vuelidate/lib/validators';

export default {
  components: {
    PageHeader,
  },
  mixins: [inboxMixin, alertMixin],
  data() {
    return {
      name: null,
      routing: null,
      greetingMessage: null,
    };
  },
  validations: {
    greetingMessage: {
      maxLength: maxLength(250),
      minLength: minLength(50),
    },
  },
  created() {
    this.greetingMessage = this.getDefaultGreeting;
    if (this.newInboxType !== 'call') {
      this.goToGivenStep('settings_inbox_new');
    }
  },
  methods: {
    async updateInbox() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        const data = {
          id: this.$route.params.inbox_id,
          greeting_message: this.greetingMessage || this.getDefaultGreeting,
        };
        await this.$store.dispatch('inboxes/updateInbox', data);
        this.showAlert(
          this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'),
          'success'
        );

        const newInbox = { ...this.newInboxData };
        newInbox.greeting_message = data.greeting_message;
        newInbox.id = data.id;
        this.setNewInboxData(newInbox);

        this.goToGivenStep('settings_call_inbox_finish', {
          inbox_id: this.$route.params.inbox_id,
        });
      } catch (error) {
        this.showAlert(`Error: ${error?.message}`, 'error');
      }
    },
  },
};
</script>
<style scoped lang="scss">
.attributes-content {
  max-width: 75%;
}
</style>
