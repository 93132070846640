import { render, staticRenderFns } from "./ImapSettings.vue?vue&type=template&id=4fcc8710&scoped=true&"
import script from "./ImapSettings.vue?vue&type=script&lang=js&"
export * from "./ImapSettings.vue?vue&type=script&lang=js&"
import style0 from "./ImapSettings.vue?vue&type=style&index=0&id=4fcc8710&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fcc8710",
  null
  
)

export default component.exports