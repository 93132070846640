var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"missed-call-item",on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[(!_vm.hover)?_c('div',{staticClass:"missed-call-info missed-call-block"},[_c('div',{staticClass:"missed-call-avatar"},[(_vm.callerCountry)?_c('VFlag',{staticClass:"phone-number-flag margin-right-2",attrs:{"flag":_vm.callerCountry}}):_vm._e(),_vm._v(" "),(_vm.callerImg)?_c('img',{staticClass:"missed-call-avatar-img",attrs:{"src":_vm.callerImg}}):_c('Avatar',{staticClass:"missed-call-sender-name",attrs:{"username":_vm.callerName}})],1),_vm._v(" "),_c('div',{staticClass:"missed-call-data"},[_c('div',{staticClass:"missed-call-user-name"},[_c('div',{staticClass:"fs-default fw-medium"},[_c('span',{staticClass:"caller-name"},[_vm._v("\n            "+_vm._s(_vm.callerName)+"\n          ")])])]),_vm._v(" "),_c('div',{staticClass:"missed-call-desc"},[_c('div',{staticClass:"fs-default missed-call-status"},[_vm._v("\n          "+_vm._s(_vm.$t('CONVERSATION.MISSED_CALL'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"fw-medium fs-mini"},[_vm._v("\n          "+_vm._s(_vm.dynamicTime(_vm.missedCallData.created_at))+"\n        ")])])])]):_c('div',{staticClass:"missed-call-actions missed-call-block"},[_c('div',{staticClass:"missed-call-action-events"},[_c('div',{class:{
          call: _vm.callerNumber,
          missing: !_vm.callerNumber,
          'missed-call-action-event': true,
        },on:{"click":function($event){return _vm.callAgain()}}},[_c('fluent-icon',{attrs:{"icon":"missed-call","size":"18","view-box":"0 0 18 18","fill-color":"#ffffff"}}),_vm._v(" "),_c('span',{staticClass:"margin-left-1 margin-right-1 fw-medium"},[_vm._v(_vm._s(_vm.$t('CONVERSATION.CALL_BACK')))])],1),_vm._v(" "),_c('div',{staticClass:"missed-call-action-event go-to margin-left-1 margin-right-1",on:{"click":function($event){return _vm.execGoToConversation(_vm.missedCallData.primary_actor.id)}}},[_c('fluent-icon',{attrs:{"icon":"call-widget-right-arrow","size":"16","view-box":"0 0 16 16","fill-color":"#ffffff"}})],1)]),_vm._v(" "),_c('div',{staticClass:"missed-call-action-event close",on:{"click":function($event){$event.stopPropagation();return _vm.clearMissedCall(
          _vm.missedCallData.primary_actor_id,
          _vm.missedCallData.primary_actor_type,
          _vm.missedCallData.primary_actor.id
        )}}},[_c('span',{staticClass:"fw-medium"},[_vm._v(_vm._s(_vm.$t('CONVERSATION.HEADER.CLEAR')))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }