import { render, staticRenderFns } from "./EditLabel.vue?vue&type=template&id=617c5923&scoped=true&"
import script from "./EditLabel.vue?vue&type=script&lang=js&"
export * from "./EditLabel.vue?vue&type=script&lang=js&"
import style0 from "./EditLabel.vue?vue&type=style&index=0&id=617c5923&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "617c5923",
  null
  
)

export default component.exports