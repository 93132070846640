<template>
  <div class="column content-box">
    <div class="row table-view">
      <div class="small-8 columns with-right-space ">
        <p
          v-if="!teamsList.length && !isLoading"
          class="no-items-error-message"
        >
          {{ $t('TEAMS_SETTINGS.LIST.404') }}
          <router-link
            v-if="isAdmin"
            :to="addAccountScoping('settings/teams/new')"
          >
            {{ $t('TEAMS_SETTINGS.NEW_TEAM') }}
          </router-link>
        </p>
        <spinner-hoory v-if="isLoading" />
        <TeamTable
          v-else
          :items="teamsList"
          @deleteTeam="openDelete"
          @redirectToSettings="redirectToTeamSettings"
        />
        <table-footer
          :current-page="Number(meta.currentPage)"
          :total-count="meta.count"
          :page-size="20"
          @page-change="onPageChange"
        />
      </div>

      <div class="small-4 columns">
        <span
          v-dompurify-html="
            $t('TEAMS_SETTINGS.SIDEBAR_TXT', {
              installationName: globalConfig.installationName,
            })
          "
        />
      </div>
    </div>
    <woot-confirm-delete-modal
      v-if="showDeletePopup"
      :show.sync="showDeletePopup"
      :title="confirmDeleteTitle"
      :message="$t('TEAMS_SETTINGS.DELETE.CONFIRM.MESSAGE')"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
      :confirm-value="selectedTeam.name"
      :confirm-place-holder-text="confirmPlaceHolderText"
      @on-confirm="confirmDeletion"
      @on-close="closeDelete"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import adminMixin from '../../../../mixins/isAdmin';
import accountMixin from '../../../../mixins/account';
import alertMixin from 'shared/mixins/alertMixin';
import SpinnerHoory from '../../../../../shared/components/SpinnerHoory.vue';
import TeamTable from './TeamTable.vue';
import TableFooter from '../../../../components/widgets/TableFooter.vue';

export default {
  components: { TableFooter, TeamTable, SpinnerHoory },
  mixins: [adminMixin, accountMixin, alertMixin],
  data() {
    return {
      isLoading: false,
      showSettings: false,
      showDeletePopup: false,
      selectedTeam: {},
    };
  },
  computed: {
    ...mapGetters({
      teamsList: 'teams/getTeams',
      meta: 'teams/getMeta',
      globalConfig: 'globalConfig/get',
    }),
    deleteConfirmText() {
      return `${this.$t('TEAMS_SETTINGS.DELETE.CONFIRM.YES')} ${
        this.selectedTeam.name
      }`;
    },
    deleteRejectText() {
      return this.$t('TEAMS_SETTINGS.DELETE.CONFIRM.NO');
    },
    confirmDeleteTitle() {
      return this.$t('TEAMS_SETTINGS.DELETE.CONFIRM.TITLE', {
        teamName: this.selectedTeam.name,
      });
    },
    confirmPlaceHolderText() {
      return `${this.$t('TEAMS_SETTINGS.DELETE.CONFIRM.PLACE_HOLDER', {
        teamName: this.selectedTeam.name,
      })}`;
    },
  },
  mounted() {
    this.fetchTeams(this.meta.currentPage);
  },
  methods: {
    async fetchTeams(page) {
      this.isLoading = true;
      await this.$store.dispatch('teams/get', { page });
      this.isLoading = false;
    },
    onPageChange(page) {
      this.fetchTeams(page);
    },
    async deleteTeam({ id }) {
      try {
        await this.$store.dispatch('teams/delete', id);
        await this.$store.dispatch('teams/updateMeta', {
          count: this.meta.count - 1,
          current_page: this.meta.currentPage,
        });
        this.showAlert(
          this.$t('TEAMS_SETTINGS.DELETE.API.SUCCESS_MESSAGE'),
          'success'
        );
      } catch (error) {
        this.showAlert(
          this.$t('TEAMS_SETTINGS.DELETE.API.ERROR_MESSAGE'),
          'error'
        );
      }
    },
    redirectToTeamSettings(row) {
      this.$router.push(
        `${this.addAccountScoping(`settings/teams/${row.id}/edit`)}`
      );
    },
    confirmDeletion() {
      this.deleteTeam(this.selectedTeam);
      this.closeDelete();
    },
    openDelete(team) {
      this.showDeletePopup = true;
      this.selectedTeam = team;
    },
    closeDelete() {
      this.showDeletePopup = false;
      this.selectedTeam = {};
    },
  },
};
</script>
<style lang="scss" scoped>
.button-wrapper {
  min-width: unset;
  justify-content: flex-end;
}
</style>
