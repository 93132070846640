<template>
  <transition-group name="toast-fade" tag="div" class="ui-snackbar-container">
    <template v-for="(snackMessage, index) in snackMessages">
      <woot-snackbar
        v-if="snackMessage"
        :key="snackMessage.key + index"
        :message="snackMessage.message"
        :type="snackMessage.type"
        :action="snackMessage.action"
        :title="snackMessage.title"
      />
    </template>
  </transition-group>
</template>

<script>
import WootSnackbar from './Snackbar';

export default {
  components: {
    WootSnackbar,
  },
  props: {
    duration: {
      type: Number,
      default: 5000,
    },
  },

  data() {
    return {
      snackMessages: [],
    };
  },

  mounted() {
    bus.$on('newToastMessage', this.onNewToastMessage);
  },
  beforeDestroy() {
    bus.$off('newToastMessage', this.onNewToastMessage);
  },
  methods: {
    onNewToastMessage(message, type = 'default', action = {}, title = '') {
      this.snackMessages.push({
        key: new Date().getTime(),
        message,
        type,
        action,
        title,
      });
      window.setTimeout(() => {
        this.snackMessages.splice(0, 1);
      }, this.duration);
    },
  },
};
</script>
