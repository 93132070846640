export const SET_TEAM_UI_FLAG = 'SET_TEAM_UI_FLAG';
export const CLEAR_TEAMS = 'CLEAR_TEAMS';
export const SET_TEAMS = 'SET_TEAMS';
export const SET_TEAM_ITEM = 'SET_TEAM_ITEM';
export const EDIT_TEAM = 'EDIT_TEAM';
export const DELETE_TEAM = 'DELETE_TEAM';
export const SET_TEAM_META = 'SET_TEAM_META';
export const UPDATE_TEAM_META = 'UPDATE_TEAM_META';
export const ADD_AGENTS_TO_TEAM = 'ADD_AGENTS_TO_TEAM';
export const UPDATE_TEAMS_PRESENCE = 'UPDATE_TEAMS_PRESENCE';
